$(function () {

    $.ajaxSetup(
        {
            headers:
            {
                'X-CSRF-Token': $('input[name="_token"]').val()
            }
        });

    if ($('#klopal-media').length) {
        /**
         * Check that all necessary data is initialized.
         */
        if (typeof Klopal.model == 'undefined') {
            console.log('model must be set!')
        }

        if (typeof Klopal.model_type == 'undefined') {
            console.log('model_type must be set!')
        }

        /**
         * Init Dropzone.
         *
         * @type {{paramName: string, url: string, autoProcessQueue: boolean, uploadMultiple: boolean, addRemoveLinks: boolean, parallelUploads: number, maxFilesize: number, acceptedFiles: string, init: Dropzone.options.images.init, headers: {X-CSRF-Token: (*|jQuery)}}}
         */
        klopalDropzone = Dropzone.options.klopalMedia = {
            paramName: 'file',
            url: '/dashboard/file',
            autoProcessQueue: true,
            uploadMultiple: true,
            addRemoveLinks: false,
            parallelUploads: 1,
            maxFilesize: 30, // MB
            acceptedFiles: "image/jpeg,image/jpg,image/png,image/gif,.pdf",

            init: function () {
                thisDropzone = this;

                this.on("sending", function (file, xhr, formData) {

                    // Append data to request for controller
                    formData.append('model_id', Klopal.model.id);
                    formData.append('model_type', Klopal.model_type);
                    formData.append('collection_name', Klopal.collection_name);
                });

                this.on("success", function (xhr, file) {

                    console.log(file);

                    // Lets clone template DOM
                    var template = $('#image-template').clone();

                    // Add file ID to DOM ID attribute for sorting
                    template.prop('id', file.data.id);

                    // Add image
                    template.find('#template-image').prop('src', file.data.small_url);
                    template.find('.template-image-large').prop('href', file.data.large_url);

                    // Add ids to hidden field
                    template.find('[name=id]').prop('value', file.data.id);
                    template.find('[name=model_id]').prop('value', file.data.model_id);
                    template.find('[name=model_type]').prop('value', file.data.model_type);
                    template.find('[name=token]').prop('value', file.data.token);

                    // Append image to the list
                    template.find('.hidden-for-fadein').appendTo('#sortable-view').fadeIn(600);

                    // Notify user that everything went better than excepted :)
                    toastr.success(file.message);
                });

                this.on('fail', function (file) {
                    toastr.error(file.message);
                });

                // Remove thumbnail from dropzone
                this.on('complete', function (file) {
                    thisDropzone.removeFile(file);
                });

                this.on('maxfilesexceeded', function() {
                    toastr.error('Et voi ladata enempää kuvia. Poista tarvittaessa tiedostoja.');
                });
            },

            headers: {
                'X-CSRF-Token': $('[name="csrf-token"]').attr('content')
            }
        };

        /**
         * Delete single file.
         */
        $(document).on('submit', '.delete-form', function (e) {
            e.preventDefault();

            var id = $(this).find('[name="id"]').attr('value');

            $.ajax({
                    type: 'POST',
                    url: $(this).attr('action'),
                    data: {
                        _token: $('[name="csrf-token"]').attr('content'),
                        _method: 'delete',
                        id: id,
                        model_type: $(this).find('[name="model_type"]').attr('value'),
                        model_id: $(this).find('[name="model_id"]').attr('value'),
                        token: $(this).find('[name="token"]').attr('value'),
                    },
                    dataType: 'json'
                })
                .done(function (msg) {
                    $('#' + id).fadeOut();

                    toastr.success(msg.message);
                })
                .fail(function (jqXHR, msg) {
                    toastr.error(msg);
                });
        });

        /**
         * Update custom form fields.
         */
        $(document).on('submit', '.update-form', function (e) {
            e.preventDefault();

            var id = $(this).find('[name="id"]').attr('value');
            var data = $(this).serialize();

            $.ajax({
                    type: 'POST',
                    url: $(this).attr('action'),
                    data: data,
                    dataType: 'json'
                })
                .done(function (msg) {
                    toastr.success(msg.message);
                })
                .fail(function (jqXHR, msg) {
                    toastr.error(msg);
                });
        });

        /**
         * Make files sorttable.
         */
        $("#sortable-view").sortable({
            items: '.ui-sortable',
            handle: '.draggable',
            cursor: 'move',
            opacity: 0.5,
            containment: "parent",
            distance: 20,
            tolerance: 'pointer',
            update: function (e, ui) {

                var newOrder = $(this).sortable('toArray');

                $.ajax({
                        type: 'POST',
                        url: '/dashboard/file/sort',
                        data: {
                            ids: newOrder,
                            _token: $('[name="csrf-token"]').attr('content'),
                            _method: 'put'
                        },
                        dataType: 'json'
                    })
                    .done(function (msg) {
                        toastr.success(msg.message);
                    })
                    .fail(function (jqXHR, textStatus) {
                        toastr.error(textStatus);
                    });
            }
        });
    }
});