/**
 * Common functions used around the code
 */

function convertDateToMoment() {
    var date;

    // Find all elements with moment-date class defined
    $('.moment-date').each(function () {

        var dateFormat = 'LLL';

        // Create Date object from the value
        date = moment($(this).text().trim());

        // Check if the date is valid and could be converted
        if (moment(date).isValid()) {
            // Convert containing datetime into a localised format

            // If data-dateformat is set
            if ($(this).data('dateformat') != undefined) {
                dateFormat = $(this).data('dateformat');
            }

            $(this).text(moment(date).format(dateFormat));
        }
    });
}