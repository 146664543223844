/**
 * Part of the Sentinel Kickstart application.
 *
 * NOTICE OF LICENSE
 *
 * Licensed under the Cartalyst PSL License.
 *
 * This source file is subject to the Cartalyst PSL License that is
 * bundled with this package in the license.txt file.
 *
 * @package    Sentinel Kickstart
 * @version    3.0.0
 * @author     Cartalyst LLC
 * @license    Cartalyst PSL
 * @copyright  (c) 2011-2014, Cartalyst LLC
 * @link       http://cartalyst.com
 */

 (function() {

 	var App = {};

 	App.Sentinel = {

 		init: function() {

			// Scope Helper
			this._bind = function(fn, me) {
				return function() {
					return fn.apply(me, arguments);
				};
			};

			// Add event listeners
			this.addListeners();

		},

		addListeners: function() {

            /**
             * Add 'data-action-delete' attribute to link and it will generate RESTful delete action.
             */
            $(document.body).on('click', '[data-action-delete]', function(event) {
				event.preventDefault();

				var form = $(this).parents('form:first');

				var href = $(this).attr('href');

				form.attr('action', href);

				form.append('<input type="hidden" name="_method" value="delete">');

				console.log(event.target); return;
				if($(event.target).attr('data-confirm-delete') === undefined) {
					form.submit();
				}

				return;
			});
		},

		handleSomeEvent: function () {

		},

	};

	$(function() {

		return App.Sentinel.init();

	});

}).call(this);
