/*
 *  Application scripts.
 *
 */
$(document).ready(function () {

    moment.locale('fi');

    convertDateToMoment();

    /**
     * Datatables
     */
    $('.datatable').DataTable({
        responsive: true,
        language: {
            "url": "/copy/js/plugins/datatables/lang/fi.json"
        },
        "dom": '<"top"f>rt<"bottom"lp><"clear">',
        "bLengthChange": false,
        aaSorting: [],
        "pageLength": 25,
        "bPaginate": false
    });

    /**
     * Switchery
     */
    var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));

    elems.forEach(function(html) {
        var switchery = new Switchery(html, {color: '#18A689'});
    });

    /**
     * Tooltip
     */
    $('[data-toggle="tooltip"]').tooltip()

    /**
     * Confirm delete.
     */
    $('[data-confirm-delete]').on('click', function(e) {
        e.preventDefault();
        var self = this;

        $.confirm({
            title: 'Vahvista poistaminen',
            content: 'Tätä toimintoa ei voi peruuttaa.',
            confirmButton: 'Poista',
            confirmButtonClass: 'btn-danger',
            cancelButton: 'Peruuta',
            icon: 'fa fa-2x fa-exclamation-circle text-danger',
            confirm: function() {
                $(self).closest('form').submit();
            },
            cancel: function() {
                // Do nothing.
            }
        });
    });

    /**
     * iCheck
     */
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
    }).on('ifToggled', function(e) { $(e.target).trigger('change'); });

});
